<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Account Executive
                                <router-link to="/careers/account-executive/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>The Role</h4>
                                <p>We are looking for a highly motivated and seasoned <b>Account Executive</b> who has the capacity to perform an end-to-end sales cycle for SaaS, from generating leads to closing sales. The ideal candidate is a self-starter who achieves targets and has a potential to become a leader in the future.</p>
                                <p>We know it can be tricky to apply for roles, wondering if the position is right for you and if you and your experience are suitable for the role. Many people won't apply for roles unless they feel that they tick every single box. At eFlex, we look for many different skills and abilities, and we're always looking for how new team members can add to eFlex and our culture. So if you don't think you quite meet all of the skills listed, we'd still love to hear from you!</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Able to perform the full sale cycle from generating new leads and identifying and contacting decision-makers to screening potential business opportunities and facilitating sales pitches; through to closing sales.</li>
                                            <li><i class="icon-ok"></i>Achieve sales goals by assessing partner needs and following the sales process with potential buyers, including demos and presentations</li>
                                            <li><i class="icon-ok"></i>Perform prospecting activities such as cold calling and networking</li>
                                            <li><i class="icon-ok"></i>Utilize CRM, cold calling, and email to generate new sales opportunities using tools like Outplay or Outreach.io.</li>
                                            <li><i class="icon-ok"></i>Report to sales manager with weekly, monthly, and quarterly results</li>
                                            <li><i class="icon-ok"></i>Work from home</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Minimum Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>3+ years of technology sales experience is required</li>
                                            <li><i class="icon-ok"></i>Experience working in a CRM (Salesforce is a plus!)</li>
                                            <li><i class="icon-ok"></i>Experience selling complex deals</li>
                                            <li><i class="icon-ok"></i>Experience with long sales cycles</li>
                                            <li><i class="icon-ok"></i>Confidence in interacting with high profile/big logo clientele</li>
                                            <li><i class="icon-ok"></i>Success with pipeline forecasting for revenue</li>
                                            <li><i class="icon-ok"></i>Strong written and verbal communication skills</li>
                                            <li><i class="icon-ok"></i>Self-starter with strong organizational skills and the ability to think strategically</li>
                                            <li><i class="icon-ok"></i>Positive attitude</li>
                                            <li><i class="icon-ok"></i>Grit and enjoy the hustle of prospecting</li>
                                            <li><i class="icon-ok"></i>Amenable to work on the night shift.</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i>We live our values as playmakers, obsessed with learning, care personally about our colleagues and partners, are radically open-minded, and take pride in everything we do.</li>
                                            <li><i class="icon-plus-sign"></i>We offer a Competitive Salary and Benefits package. This includes a comprehensive HMO and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i>A temporary work-from-home setup as we prepare to provide a safe environment for our employees who would like to work in the office. Our Future of Work plan is to move towards a hybrid work model (in-office & from home).</li>
                                            <li><i class="icon-plus-sign"></i>Recharge and go on your next getaway or simply take time off for yourself through our flexible personal & sick days. We want our team to be happy and healthy :)</li>
                                            <li><i class="icon-plus-sign"></i>We support our employee's career growth and development by offering opportunities for promotion.</li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-title">
                                            About eFlexervices
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>eFlexervices is a 23-year-old BPO company providing exceptional quality and unwavering trust. Our partnership approach to the business allows us to match the right talent to every organization we support. We invest in people to optimize performance and maximize efficiency. We work hard to produce the highest possible results for our partners.</p>
                                        <p>Performance is the underlying foundation that drives eFlexervices. We deliver the metrics our partners expect through proper recruitment and heavy investment in the right people.</p>
                                        <p>Deep integration within our partners' organizations drives our team to take ownership of their work. This stakeholder mindset pushes higher performance, better quality, and longer retention.</p>
                                    </div>
                                    <router-link to="/careers/account-executive/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>